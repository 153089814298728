import { createSlice } from '@reduxjs/toolkit';
import { baseUserDetailPostReducers, baseUserDetailPostState } from '../../../services/detail-post-service/user/reducer';
import { DETAIL_LEADER_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
var userDetailLeaderPostServiceState = createSlice({
    name: DETAIL_LEADER_POST_ROOT_SERVICES_REDUCER_NAMESPACES.userDetailPost,
    initialState: baseUserDetailPostState,
    reducers: baseUserDetailPostReducers,
});
export var actions = userDetailLeaderPostServiceState.actions;
export default userDetailLeaderPostServiceState.reducer;
