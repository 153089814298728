import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useShowSupportRequestFormActions } from 'pages/services/show-support-request-form-service/hooks';
import { OPEN_SUPPORT_RUQUEST_FORM_HASH_KEY } from 'common/constants/common';
export var HashUrlListener = function () {
    var openSupportRequestForm = useShowSupportRequestFormActions().openSupportRequestForm;
    var location = useLocation();
    var navigate = useNavigate();
    useEffect(function () {
        if (location.hash === OPEN_SUPPORT_RUQUEST_FORM_HASH_KEY) {
            openSupportRequestForm();
            navigate(location.pathname + location.search, { replace: true });
        }
    }, [navigate, openSupportRequestForm, location]);
    return null;
};
