import { createSelector } from 'reselect';
import { appServicesRootSelector } from '../app-services-root-selector';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
var rootName = APP_SERVICES_REDUCER_NAMESPACES.common;
var stateName = APP_SERVICES_REDUCER_NAMESPACES.supportRequestTypes;
export var supportRequestTypesServiceRootSelector = createSelector(appServicesRootSelector, function (state) { return state && state[stateName]; });
export var supportRequestTypesServiceOptionsSelector = createSelector(supportRequestTypesServiceRootSelector, function (state) {
    return state
        ? state.data.content.map(function (item) { return ({
            value: item.id,
            label: item.name,
            desc: item.description,
        }); })
        : [];
});
export var supportRequestTypesServiceInitializedSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].initialized);
};
export var supportRequestTypesServiceLoadingSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].loading);
};
export var supportRequestTypesServiceIsRequestsAvailableSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].data.content.length);
};
export var supportRequestTypesServiceTypeOptionSelector = function (id) {
    return createSelector(supportRequestTypesServiceRootSelector, function (state) {
        if (!id || !state || !state.data)
            return null;
        var requsetType = state.data.content.find(function (user) { return user.id === id; });
        return (requsetType === null || requsetType === void 0 ? void 0 : requsetType.name) || null;
    });
};
