var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowSupportRequestFormActions } from 'pages/services/show-support-request-form-service/hooks';
import { ControlButton } from 'common/layouts/main-layout/modules/control-button';
import { LogoutIcon, HelpIcon } from 'common/layouts/icons';
import { userService } from 'common/utils/user-service';
import styles from './footer.module.scss';
export var Footer = memo(function () {
    var openSupportRequestForm = useShowSupportRequestFormActions().openSupportRequestForm;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: styles.footer }, { children: [_jsx(ControlButton, { className: styles.action, label: t('common.menu.openSupportRequestFormButtonText'), icon: HelpIcon, onClick: openSupportRequestForm }), _jsx(ControlButton, { className: styles.action, label: t('common.menu.logout'), icon: LogoutIcon, onClick: userService.doLogout })] })));
});
