export var ChartType;
(function (ChartType) {
    ChartType["NumberOfEmployees"] = "NumberOfEmployees";
    ChartType["EmployeeTurnover"] = "EmployeeTurnover";
})(ChartType || (ChartType = {}));
export var ChartPeriodType;
(function (ChartPeriodType) {
    ChartPeriodType["ByDays"] = "ByDays";
    ChartPeriodType["ByMonths"] = "ByMonths";
})(ChartPeriodType || (ChartPeriodType = {}));
