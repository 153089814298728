var _a;
import { combineReducers } from 'redux';
import positionsServiceReducer from './positions-service/reducer';
import tagsServiceReducer from './tags-service/reducer';
import tariffsServiceReducer from './tariffs-service/reducer';
import tariffsInfoServiceReducer from './tariff-info-service/reducer';
import productCategoriesServiceReducer from './product-categories-service/reducer';
import showSupportRequestFormServiceReducer from './show-support-request-form-service/reducer';
import activitiesCategoriesServiceReducer from './activities-categories-service/reducer';
import postsCategoriesServiceReducer from './posts-categories-service/reducer';
import approvalChainsFilterServiceReducer from './approval-chains-service/reducer';
import activitiesMetaDataServiceReducer from './activities-meta-data-service/reducer';
import employeesForSelectsServiceReducer from './employees-for-selects-service/reducer';
import gratitudesSettingsServiceReducer from './gratitudes-settings-service/reducer';
import feedbackServiceReducer from './feedback-service/reducer';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from './constants';
export var sharedServicesRootReducer = combineReducers((_a = {},
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.positions] = positionsServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.tags] = tagsServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.tariffs] = tariffsServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.tariffsInfo] = tariffsInfoServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.productCategories] = productCategoriesServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.showSupportRequestForm] = showSupportRequestFormServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.activitiesCategories] = activitiesCategoriesServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.postsCategories] = postsCategoriesServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.approvalChains] = approvalChainsFilterServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.activitiesMetaData] = activitiesMetaDataServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.employeesForSelects] = employeesForSelectsServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.gratitudesSettings] = gratitudesSettingsServiceReducer,
    _a[SHARED_SERVICES_REDUCER_NAMESPACES.feedback] = feedbackServiceReducer,
    _a));
