import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useShowSupportRequestFormActions } from 'pages/services/show-support-request-form-service/hooks';
import { ControlButton } from '../../../control-button';
import { HelpIcon } from 'common/icons';
export var Footer = function (_a) {
    var className = _a.className;
    var openSupportRequestForm = useShowSupportRequestFormActions().openSupportRequestForm;
    var t = useTranslation().t;
    return (_jsx(ControlButton, { className: className, label: t('common.menu.openSupportRequestFormButtonText'), icon: HelpIcon, onClick: openSupportRequestForm }));
};
