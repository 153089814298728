import { createSlice } from '@reduxjs/toolkit';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    isOpenSupportRequestForm: false,
};
var showSupportRequestFormServiceState = createSlice({
    name: SHARED_SERVICES_REDUCER_NAMESPACES.showSupportRequestForm,
    initialState: initialState,
    reducers: {
        openSupportRequestForm: function (state) {
            state.isOpenSupportRequestForm = true;
        },
        closeSupportRequestForm: function (state) {
            state.isOpenSupportRequestForm = false;
        },
        reset: function () { return initialState; },
    },
});
export var actions = showSupportRequestFormServiceState.actions;
export default showSupportRequestFormServiceState.reducer;
