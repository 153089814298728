var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef } from 'react';
import cs from 'classnames';
import { InputBaseRoot } from './modules';
import { parseNumber } from './helpers';
import styles from './input-base.module.scss';
export var InputBase = function (_a) {
    var _b;
    var type = _a.type, disabled = _a.disabled, autoFocus = _a.autoFocus, additional = _a.additional, isDeactivated = _a.isDeactivated, _c = _a.isAutoComplete, isAutoComplete = _c === void 0 ? true : _c, onBlur = _a.onBlur, onChange = _a.onChange, prepareValue = _a.prepareValue, beforeOnBlur = _a.beforeOnBlur, toggleActive = _a.toggleActive, onClear = _a.onClear, rest = __rest(_a, ["type", "disabled", "autoFocus", "additional", "isDeactivated", "isAutoComplete", "onBlur", "onChange", "prepareValue", "beforeOnBlur", "toggleActive", "onClear"]);
    var inputRef = useRef(null);
    var isHoveredRef = useRef(false);
    var currentType = type === 'number' ? 'text' : type;
    useEffect(function () {
        var _a, _b;
        if (!disabled) {
            if (autoFocus) {
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
            else if (!isAutoComplete) {
                (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.setAttribute('readonly', 'true');
            }
        }
    }, [autoFocus, isAutoComplete, disabled]);
    var actions = useMemo(function () { return ({
        clickHandler: function () {
            var _a;
            if (!disabled && document.activeElement !== inputRef.current) {
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
        },
        hoverOnHandler: function () {
            if (!disabled)
                isHoveredRef.current = true;
        },
        hoverOffHandler: function () {
            if (!disabled)
                isHoveredRef.current = false;
        },
        deleteReadonlyAttr: function () {
            var _a, _b;
            if (!isAutoComplete && ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.readOnly)) {
                (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.removeAttribute('readonly');
            }
        },
        changeHandler: function (event) {
            if (isDeactivated)
                return;
            var value = type === 'number' ? parseNumber(event.target.value) : event.target.value;
            if (prepareValue)
                value = prepareValue(value);
            event.target.value = value;
            onChange(event, value);
        },
        blurHandler: function () {
            var _a, _b;
            if (!isHoveredRef.current) {
                beforeOnBlur === null || beforeOnBlur === void 0 ? void 0 : beforeOnBlur();
                onBlur === null || onBlur === void 0 ? void 0 : onBlur();
                !isAutoComplete && ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.setAttribute('readonly', 'true'));
                return;
            }
            navigator.userAgent.includes('Firefox')
                ? setTimeout(function () {
                    var _a;
                    (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                }, 0)
                : (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        },
    }); }, [onBlur, onChange, prepareValue, beforeOnBlur, disabled, isDeactivated, isAutoComplete, type]);
    if (!additional)
        return (_jsx(InputBaseRoot, __assign({ ref: inputRef, disabled: disabled, type: currentType, onClear: onClear, onChange: actions.changeHandler, onClick: actions.clickHandler, onBlur: actions.blurHandler, onMouseDown: actions.deleteReadonlyAttr, onMouseEnter: actions.hoverOnHandler, onMouseLeave: actions.hoverOffHandler }, rest)));
    return (_jsxs("div", __assign({ className: cs(styles.select, (_b = {}, _b[styles.disabled] = disabled, _b)), onClick: actions.clickHandler, onMouseEnter: actions.hoverOnHandler, onMouseLeave: actions.hoverOffHandler }, { children: [_jsx(InputBaseRoot, __assign({ ref: inputRef, isDeactivated: isDeactivated, disabled: disabled, type: currentType, onChange: onChange, onClick: toggleActive, onBlur: actions.blurHandler, onMouseDown: actions.deleteReadonlyAttr }, rest)), additional] })));
};
