var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { ControllerWrapper } from '../../controller-wrapper';
import { Counter } from '../modules/counter';
import { SelectController } from './modules';
import { checkEqual } from 'common/helpers/checkEqual';
export var Select = function (_a) {
    var label = _a.label, isRequired = _a.isRequired, helperText = _a.helperText, isError = _a.isError, multiple = _a.multiple, maxSelectCount = _a.maxSelectCount, showCounter = _a.showCounter, value = _a.value, options = _a.options, rest = __rest(_a, ["label", "isRequired", "helperText", "isError", "multiple", "maxSelectCount", "showCounter", "value", "options"]);
    var tagsCounterView = null;
    var selectOptions = useSelector(typeof options === 'function' ? options : function () { return options; }, checkEqual);
    var selectValue = useSelector(typeof value === 'function' ? value : function () { return value; }, checkEqual);
    if (multiple && showCounter && maxSelectCount) {
        tagsCounterView = _jsx(Counter, { maxCount: maxSelectCount, currentCount: (selectValue === null || selectValue === void 0 ? void 0 : selectValue.length) || 0 });
    }
    return (_jsx(ControllerWrapper, __assign({ label: label, helperText: helperText, isRequired: isRequired, isError: isError, labelAdditional: tagsCounterView }, { children: _jsx(SelectController, __assign({ isError: isError, value: selectValue, multiple: multiple, maxSelectCount: maxSelectCount, options: selectOptions }, rest)) })));
};
