var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Loader } from 'ui-kit/loader';
import styles from './button.module.scss';
export var Button = function (_a) {
    var _b;
    var _c = _a.variant, variant = _c === void 0 ? 'primary' : _c, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, loading = _a.loading, active = _a.active, disabled = _a.disabled, fullWidth = _a.fullWidth, className = _a.className, children = _a.children, onClick = _a.onClick, onClickSimple = _a.onClickSimple, rest = __rest(_a, ["variant", "leftIcon", "rightIcon", "loading", "active", "disabled", "fullWidth", "className", "children", "onClick", "onClickSimple"]);
    var style = classNames(styles.root, styles[variant], className, (_b = {},
        _b[styles.disabled] = disabled || loading,
        _b[styles.active] = active,
        _b[styles.fullWidth] = fullWidth,
        _b));
    var handleClick = function (e) {
        if (!disabled && !active && !loading) {
            onClick ? onClick(e) : onClickSimple === null || onClickSimple === void 0 ? void 0 : onClickSimple();
        }
    };
    if (variant && variant === 'text') {
        var paddingLeft = !(loading || leftIcon) ? '0' : undefined;
        var paddingRight = !rightIcon ? '0' : undefined;
        return (_jsxs("button", __assign({ className: style, onClick: handleClick }, rest, { children: [loading ? _jsx(Loader, { className: styles.loader }) : leftIcon, _jsx("span", __assign({ className: styles.content, style: { paddingLeft: paddingLeft, paddingRight: paddingRight } }, { children: children })), rightIcon] })));
    }
    return (_jsxs("button", __assign({ className: style, disabled: disabled, onClick: handleClick }, rest, { children: [loading ? _jsx(Loader, { className: styles.loader }) : leftIcon, _jsx("span", __assign({ className: styles.content }, { children: children })), rightIcon] })));
};
