var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { profileServiceMainDataSelector } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { UserAvatar } from 'ui-kit/user-avatar';
import { ControlButton } from '../../../control-button';
import { LogoutIcon } from 'common/layouts/icons';
import { userService } from 'common/utils/user-service';
import { checkEqual } from 'common/helpers/checkEqual';
export var Header = memo(function () {
    var _a = useSelector(profileServiceMainDataSelector, checkEqual), image = _a.image, firstName = _a.firstName, lastName = _a.lastName;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: t('common.menu.showMore') })), _jsxs(Box, __assign({ pt: 8, alignItems: "center" }, { children: [_jsx(UserAvatar, { mr: 4, imagePath: image === null || image === void 0 ? void 0 : image.fullPath, firstName: firstName, lastName: lastName }), _jsx(Box, __assign({ pl: 4, marginAuto: "left" }, { children: _jsx(ControlButton, { isReverseIcon: true, label: t('common.menu.logout'), icon: LogoutIcon, onClick: userService.doLogout }) }))] }))] }));
});
