var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Outlet } from 'react-router';
import cs from 'classnames';
import { PageLoader } from 'ui-kit/loader';
import { RenderOnBreakpoints } from 'common/components/render-on-breakpoints';
import { FeedbackModal } from 'pages/modules/feedback-modal';
import { CreateRequestForm, MobileFooter, MobileHeader, MobileMenu, Sidebar } from './modules';
import styles from './main-layout.module.scss';
export var MainLayout = function (_a) {
    var _b;
    var withRiseze = _a.withRiseze, withoutMobileMenu = _a.withoutMobileMenu;
    return (_jsxs("div", __assign({ className: cs(styles.root, { mainLayoutOld: withRiseze }) }, { children: [_jsx(RenderOnBreakpoints, __assign({ screen: "desktop", or: !withoutMobileMenu && (_jsxs(_Fragment, { children: [_jsx(MobileHeader, {}), _jsx(MobileMenu, {}), _jsx(MobileFooter, {})] })) }, { children: _jsx(Sidebar, {}) })), _jsx("main", __assign({ className: cs(styles.content, (_b = {}, _b[styles.withMobileMenu] = !withoutMobileMenu, _b)) }, { children: _jsx(Suspense, __assign({ fallback: _jsx(PageLoader, { variant: "medium" }) }, { children: _jsx(Outlet, {}) })) })), _jsx(CreateRequestForm, {}), _jsx(FeedbackModal, {})] })));
};
