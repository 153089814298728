var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign({ data: null }, baseStateProperties);
var profileServiceState = createSlice({
    name: APP_SERVICES_REDUCER_NAMESPACES.profile,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            state.initialized = true;
        }, buyProduct: function (state, action) {
            if (state.data)
                state.data.tealBalance -= action.payload;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = profileServiceState.actions;
export default profileServiceState.reducer;
