import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
export var setupStore = function () {
    return configureStore({
        reducer: rootReducer,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: false,
            });
        },
        devTools: process.env.NODE_ENV === 'development',
    });
};
