import * as Yup from 'yup';
import { t } from 'i18next';
import { getMaxFilesSizeSchema } from 'common/schemas';
export var CreateSupportRequestFormSchema = Yup.object().shape({
    typeId: Yup.string().required(t('common.createSupportRequestForm.errors.typeIdReq')),
    creatorId: Yup.string().required(t('common.createSupportRequestForm.errors.creatorIdReq')).nullable(),
    text: Yup.string()
        .max(1000, t('common.errorTexts.maxLength', { count: 1000 }))
        .required(t('common.createSupportRequestForm.errors.textReq')),
    formFileCollection: getMaxFilesSizeSchema(),
});
