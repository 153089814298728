var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { REQUESTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    modalFilterValues: {
        createdDatesInterval: null,
        executorIds: [],
        priority: undefined,
        creatorId: undefined,
        supportRequestTypeId: undefined,
    },
    isArchived: false,
    isOpenFiter: false,
    isOpenCreateRequestForm: false,
};
var requestsFilterServiceState = createSlice({
    name: REQUESTS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        toggleIsArchivedStatus: function (state) {
            state.isArchived = !state.isArchived;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterField: function (state, action) {
            var _a, _b, _c;
            var key = action.payload;
            if (key === 'executorIds') {
                state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_a = {}, _a[key] = [], _a));
            }
            else if (key === 'createdDatesInterval') {
                state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_b = {}, _b[key] = null, _b));
            }
            else {
                state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_c = {}, _c[key] = undefined, _c));
            }
        },
        removeExecutorId: function (state, action) {
            var userId = action.payload;
            if (state.modalFilterValues.executorIds.includes(userId)) {
                state.modalFilterValues.executorIds = state.modalFilterValues.executorIds.filter(function (id) { return userId !== id; });
            }
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        },
        openFilter: function (state) {
            state.isOpenFiter = true;
        },
        closeFilter: function (state) {
            state.isOpenFiter = false;
        },
        openCreateRequestForm: function (state) {
            state.isOpenCreateRequestForm = true;
        },
        closeCreateRequestForm: function (state) {
            state.isOpenCreateRequestForm = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = requestsFilterServiceState.actions;
export default requestsFilterServiceState.reducer;
