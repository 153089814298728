import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useShowSupportRequestFormActions } from 'pages/services/show-support-request-form-service/hooks';
import { showSupportRequestFormServiceIsOpenSupportRequestFormSelector } from 'pages/services/show-support-request-form-service/selectors';
import { CreateSupportRequestForm } from 'common/components/create-support-reuest-form';
export var CreateRequestForm = function () {
    var closeSupportRequestForm = useShowSupportRequestFormActions().closeSupportRequestForm;
    var isOpen = useSelector(showSupportRequestFormServiceIsOpenSupportRequestFormSelector);
    return _jsx(CreateSupportRequestForm, { isOpen: isOpen, onClose: closeSupportRequestForm });
};
