var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import styles from './controller-wrapper.module.scss';
export var ControllerWrapper = function (_a) {
    var label = _a.label, labelAdditional = _a.labelAdditional, helperText = _a.helperText, isRequired = _a.isRequired, isError = _a.isError, children = _a.children;
    var labelData = label || labelAdditional ? (_jsxs(Box, __assign({ className: styles.label, display: "flex", alignItems: "center" }, { children: [label ? (_jsxs(Typography, __assign({ variant: "body-s" }, { children: [label, "\u00A0", isRequired && (_jsx(Typography, __assign({ color: "error", component: "span" }, { children: "*\u00A0" })))] }))) : null, labelAdditional] }))) : null;
    return (_jsxs("div", __assign({ className: styles.controllerWrapper }, { children: [labelData, children, helperText ? (_jsx(Typography, __assign({ className: styles.helperText, variant: "body-s", color: isError ? 'error' : undefined }, { children: helperText }))) : null] })));
};
