var _a;
import { combineReducers } from 'redux';
import corporateCurrencyStatisticsServiceReducer from './corporate-currency-statistics-service/reducer';
import corporateCurrencyChartServiceReducer from './corporate-currency-chart-service/reducer';
import corporateCurrencyFilterServiceReducer from './corporate-currency-filter-service/reducer';
import corporateCurrencyWidgetsServiceReducer from './corporate-currency-widgets-service/reducer';
import { CORPORATE_CURRENCY_SERVICES_REDUCER_NAMESPACES } from './constants';
export var corporateCurrencyStatisticsRootReducer = combineReducers((_a = {},
    _a[CORPORATE_CURRENCY_SERVICES_REDUCER_NAMESPACES.corporateCurrencyStatistics] = corporateCurrencyStatisticsServiceReducer,
    _a[CORPORATE_CURRENCY_SERVICES_REDUCER_NAMESPACES.corporateCurrencyStatisticsChart] = corporateCurrencyChartServiceReducer,
    _a[CORPORATE_CURRENCY_SERVICES_REDUCER_NAMESPACES.corporateCurrencyStatisticsFilter] = corporateCurrencyFilterServiceReducer,
    _a[CORPORATE_CURRENCY_SERVICES_REDUCER_NAMESPACES.corporateCurrencyStatisticsWidgets] = corporateCurrencyWidgetsServiceReducer,
    _a));
