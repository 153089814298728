var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box } from 'ui-kit/box';
import { Button } from 'ui-kit/button';
export var OptionsListFooter = function (_a) {
    var disabled = _a.disabled, onReset = _a.onReset;
    var t = useTranslation().t;
    return (_jsx(Box, __assign({ justify: "flex-end" }, { children: _jsx(Button, __assign({ type: "button", variant: "text", disabled: disabled, onClick: onReset }, { children: t('uiKit.optionsList.resetButtonText') })) })));
};
