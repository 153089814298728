var _a;
import { combineReducers } from 'redux';
import employeesStatisticsFilterServiceReducer from './employees-statistics-filter-service/reducer';
import employeesCountStatisticsServiceReducer from './employees-count-statistics-service/reducer';
import employeesAgeStatisticsServiceReducer from './employees-age-statistics-service/reducer';
import employeesWorkRecordStatisticsServiceReducer from './employees-work-record-statistics-service/reducer';
import staffTurnoverStatisticsServiceReducer from './staff-turnover-statistics-service/reducer';
import { TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var teamStatisticsRootReducer = combineReducers((_a = {},
    _a[TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.employeesStatisticsFilter] = employeesStatisticsFilterServiceReducer,
    _a[TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.employeesCountStatistics] = employeesCountStatisticsServiceReducer,
    _a[TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.employeesAgeStatistics] = employeesAgeStatisticsServiceReducer,
    _a[TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.employeesWorkRecordStatistics] = employeesWorkRecordStatisticsServiceReducer,
    _a[TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.staffTurnoverStatistics] = staffTurnoverStatisticsServiceReducer,
    _a));
