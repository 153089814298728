var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useToastActions } from 'common/services/toast-service/hooks';
import { useFeedbackActions } from 'pages/services/feedback-service/hooks';
import { feedbackServiceTriggerSelector } from 'pages/services/feedback-service/selectors';
import { Modal } from 'ui-kit/modal';
import { Button } from 'ui-kit/button';
import { TextAreaField } from 'ui-kit/fields/text-area-field';
import { Box } from 'ui-kit/box';
import { FormButtonSetWrapper } from 'common/components/form-button-set-wrapper';
import { RenderByObservedFieldValue } from 'common/components/render-by-observed-field-value';
import { FormErrorsToast } from 'common/components/form-errors-toast';
import { makeValidateSync } from 'common/utils/form/make-validate-sync';
import { ScoreSelect } from './modules/score-select/score-select';
import { FeedbackModalFieldsSchema, NEED_COMMENT_SCORE } from './feedback-modal-fields-schema';
import { useTitle } from './hooks';
var validate = makeValidateSync(FeedbackModalFieldsSchema);
var INITIAL_VALUES = { commentText: '', score: null };
var FORM_NAME = 'feedback';
export var FeedbackModal = function () {
    var _a = useFeedbackActions(), resetState = _a.resetState, createFeedbackAction = _a.createFeedbackAction;
    var triggerType = useSelector(feedbackServiceTriggerSelector);
    var addSuccessToast = useToastActions().addSuccessToast;
    var t = useTranslation().t;
    var title = useTitle(triggerType);
    var handleSubmit = useCallback(function (values) {
        if (triggerType) {
            var _a = values, score = _a.score, commentText = _a.commentText;
            createFeedbackAction({ commentText: commentText, score: score, triggerType: triggerType }, function () {
                addSuccessToast({
                    title: t('common.feedbackModal.toast.successTitle'),
                    message: t('common.feedbackModal.toast.successMessage'),
                });
                resetState();
            });
        }
    }, [addSuccessToast, createFeedbackAction, resetState, t, triggerType]);
    var checkCommentRender = useCallback(function (v) { return v <= NEED_COMMENT_SCORE; }, []);
    return (_jsx(Modal, __assign({ title: title, isOpen: !!triggerType, onClose: resetState }, { children: _jsx(Form, { onSubmit: handleSubmit, validate: validate, subscription: {}, initialValues: INITIAL_VALUES, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsxs("form", __assign({ name: FORM_NAME, onSubmit: handleSubmit }, { children: [_jsx(ScoreSelect, { name: "score", justify: "center", wrap: "nowrap" }), _jsx(RenderByObservedFieldValue, __assign({ observedFieldName: "score", checkRender: checkCommentRender }, { children: _jsx(Box, __assign({ mt: 16 }, { children: _jsx(TextAreaField, { isRequired: true, maxLength: 1000, name: "commentText", label: t('common.feedbackModal.commentLabel'), placeholder: t('common.feedbackModal.commentPlaceholder') }) })) })), _jsx(Modal.Footer, { children: _jsx(FormButtonSetWrapper, __assign({ observeDirty: true }, { children: function (_a) {
                                    var submitLoading = _a.submitLoading, submitDisabled = _a.submitDisabled, dirty = _a.dirty;
                                    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ type: "submit", loading: submitLoading, disabled: !dirty || submitDisabled }, { children: t('common.feedbackModal.acceptButtonText') })), _jsx(Button, __assign({ variant: "secondary", type: "button", disabled: submitLoading, style: { marginLeft: '1rem' }, onClickSimple: resetState }, { children: t('common.feedbackModal.cancelButtonText') }))] }));
                                } })) }), _jsx(FormErrorsToast, {})] })));
            } }) })));
};
