var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { SurveyLifeStatusType } from 'common/types/server-api-dtos';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { SURVEYS_SERVICES_REDUCER_NAMESPACES } from '../constants';
var initialData = {
    total: 0,
    pageData: {
        page: 0,
        pageSize: 0,
    },
    content: [],
};
export var initialState = __assign(__assign({}, baseStateListProperties), { pageSize: 10, data: initialData, movableSurvey: null, showResultModal: false, copySurveyModalData: null });
var surveysListServiceState = createSlice({
    name: SURVEYS_SERVICES_REDUCER_NAMESPACES.surveysList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, moveSurvey: function (state, action) {
            if (action.payload.lifeStatus === SurveyLifeStatusType.Archived) {
                state.data.content = state.data.content.filter(function (_a) {
                    var id = _a.id;
                    return id !== action.payload.id;
                });
            }
            else {
                var isNewItem_1 = true;
                var newData_1 = [];
                state.data.content.forEach(function (item) {
                    if (item.id === action.payload.id) {
                        newData_1.push(action.payload);
                        isNewItem_1 = false;
                    }
                    else {
                        newData_1.push(item);
                    }
                });
                if (isNewItem_1)
                    newData_1.push(action.payload);
                var preparedData = newData_1.sort(function (a, b) { return a.kanbanCardWeight - b.kanbanCardWeight; });
                state.data.content = preparedData;
            }
            state.loading = false;
        }, deleteSurveyCard: function (state, action) {
            state.data.content = state.data.content.filter(function (_a) {
                var id = _a.id;
                return id !== action.payload;
            });
        }, setMovableSurveyData: function (state, action) {
            state.movableSurvey = action.payload;
        }, removeMovableSurveyData: function (state) {
            state.movableSurvey = null;
        }, showResultModal: function (state) {
            state.showResultModal = true;
        }, hideResultModal: function (state) {
            state.showResultModal = false;
        }, setCopyModalData: function (state, action) {
            state.copySurveyModalData = action.payload;
        }, hideCopyModal: function (state) {
            state.copySurveyModalData = null;
        }, resetSurveysData: function (state) {
            state.data = initialState.data;
            state.currentPage = initialState.currentPage;
            state.pageSize = initialState.pageSize;
            state.totalPages = initialState.totalPages;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = surveysListServiceState.actions;
export default surveysListServiceState.reducer;
