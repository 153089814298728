var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FeedbackTriggerType } from 'common/types/server-api-dtos';
import { createSupportRequestManagerApi, createSupportRequestUserApi } from './service/api';
import { useFeedbackActions } from 'pages/services/feedback-service/hooks';
import { EE, EventsBus } from 'common/utils/events-bus';
import { useToastActions } from 'common/services/toast-service/hooks';
import { profileServiceUserIdSelector } from 'common/services/profile-service/selectors';
import { supportRequestTypesServiceOptionsSelector } from 'common/services/support-request-types-service/selectors';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { Modal } from 'ui-kit/modal';
import { Button } from 'ui-kit/button';
import { SelectField } from 'ui-kit/fields/select-field';
import { TextAreaField } from 'ui-kit/fields/text-area-field';
import { DropzoneField } from 'ui-kit/fields/dropzone-field';
import { FormErrorsToast } from 'common/components/form-errors-toast';
import { FormButtonSetWrapper } from 'common/components/form-button-set-wrapper';
import { CreateSupportRequestFormSchema } from './create-support-reuest-form.schema';
import { makeValidateSync } from 'common/utils/form/make-validate-sync';
var FORM_NAME = 'create-support-request-form';
var ACCEPTED_FILES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
var ACCEPTED_FILES_TYPE = ACCEPTED_FILES.join(',');
var INITIAL_VALUES = {
    typeId: '',
    creatorId: '',
    text: '',
    formFileCollection: [],
};
var validate = makeValidateSync(CreateSupportRequestFormSchema);
export var CreateSupportRequestForm = function (_a) {
    var isOpen = _a.isOpen, creatorOptions = _a.creatorOptions, onClose = _a.onClose;
    var fetchNeedFeedbackAction = useFeedbackActions().fetchNeedFeedbackAction;
    var addSuccessToast = useToastActions().addSuccessToast;
    var creatorId = useSelector(profileServiceUserIdSelector);
    var t = useTranslation().t;
    var isManagerForm = !!creatorOptions;
    var handleSubmitForm = useCallback(function (values) {
        return (isManagerForm ? createSupportRequestManagerApi : createSupportRequestUserApi)(values).then(function () {
            EE.emit(EventsBus.fetchUserRequests);
            addSuccessToast({ title: t('common.createSupportRequestForm.successMessage') });
            onClose();
            fetchNeedFeedbackAction(FeedbackTriggerType.SupportRequestCreated);
        });
    }, [addSuccessToast, onClose, fetchNeedFeedbackAction, t, isManagerForm]);
    return (_jsx(Modal, __assign({ isOpen: isOpen && !!creatorId, title: t('common.createSupportRequestForm.title'), onClose: onClose }, { children: _jsx(Form, { initialValues: __assign(__assign({}, INITIAL_VALUES), { creatorId: creatorId }), onSubmit: handleSubmitForm, validate: validate, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsxs("form", __assign({ name: FORM_NAME, onSubmit: handleSubmit }, { children: [_jsx(Typography, { children: t('common.createSupportRequestForm.subTitle') }), _jsx(Box, __assign({ pt: 16, pb: 12, display: "block" }, { children: _jsx(SelectField, { isRequired: true, name: "typeId", label: t('common.createSupportRequestForm.typeIdLabel'), placeholder: t('common.createSupportRequestForm.typeIdPlaceholder'), options: supportRequestTypesServiceOptionsSelector }) })), _jsx(Box, __assign({ pb: 12, hidden: !isManagerForm, display: "block" }, { children: _jsx(SelectField, { isRequired: true, name: "creatorId", label: t('common.createSupportRequestForm.creatorIdLabel'), placeholder: t('common.createSupportRequestForm.creatorIdPlaceholder'), options: creatorOptions }) })), _jsx(TextAreaField, { isRequired: true, name: "text", label: t('common.createSupportRequestForm.textLabel'), placeholder: t('common.createSupportRequestForm.textPlaceholder'), maxLength: 1000 }), _jsx(Box, __assign({ pt: 12, display: "block" }, { children: _jsx(DropzoneField, { name: "formFileCollection", title: t('common.createSupportRequestForm.formFileCollectionLabel'), maxFiles: 10, accept: ACCEPTED_FILES_TYPE }) })), _jsx(Modal.Footer, { children: _jsx(FormButtonSetWrapper, { children: function (_a) {
                                    var submitLoading = _a.submitLoading, submitDisabled = _a.submitDisabled;
                                    return (_jsx(Button, __assign({ type: "submit", loading: submitLoading, disabled: submitDisabled }, { children: t('common.createSupportRequestForm.submitButtonText') })));
                                } }) }), _jsx(FormErrorsToast, {})] })));
            } }) })));
};
