var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastActions } from 'common/services/toast-service/hooks';
import { OptionsListFooter, OptionsListWrapper, SearchInput, SelectIcon } from '../../../modules';
import { OptionsList } from './modules';
import { useObservable } from '../../../hooks/use-observable';
export var SelectController = function (_a) {
    var isError = _a.isError, prefix = _a.prefix, postfix = _a.postfix, disabled = _a.disabled, _b = _a.options, options = _b === void 0 ? [] : _b, value = _a.value, multiple = _a.multiple, maxSelectCount = _a.maxSelectCount, onBlur = _a.onBlur, onChange = _a.onChange, rest = __rest(_a, ["isError", "prefix", "postfix", "disabled", "options", "value", "multiple", "maxSelectCount", "onBlur", "onChange"]);
    var _c = useObservable({
        searchString: '',
        isActive: false,
    }), changeState = _c.changeState, getStateValueByKey = _c.getStateValueByKey, setSubscriber = _c.setSubscriber;
    var addErrorToast = useToastActions().addErrorToast;
    var t = useTranslation().t;
    var _d = useMemo(function () {
        var optionsData = options || [];
        var currentData = Array.isArray(value) ? value : value !== undefined ? [value] : [];
        var labelsArray = optionsData.filter(function (item) { return currentData.includes(item.value); }).map(function (_a) {
            var label = _a.label;
            return label;
        });
        return {
            optionsData: optionsData,
            selectedOptions: currentData,
            labels: labelsArray.join(', '),
        };
    }, [value, options]), optionsData = _d.optionsData, selectedOptions = _d.selectedOptions, labels = _d.labels;
    var changeSearchStringHandler = useCallback(function (e) {
        if (!getStateValueByKey('isActive')) {
            changeState('isActive', true);
        }
        changeState('searchString', e.currentTarget.value);
    }, [changeState, getStateValueByKey]);
    var handleSelect = useCallback(function (value) {
        if (getStateValueByKey('searchString')) {
            changeState('searchString', '');
        }
        if (!multiple) {
            changeState('isActive', false);
            onChange(value);
            return;
        }
        var newValues = selectedOptions.includes(value)
            ? selectedOptions.filter(function (item) { return item !== value; })
            : __spreadArray(__spreadArray([], selectedOptions, true), [value], false);
        if (maxSelectCount && newValues.length > maxSelectCount) {
            return addErrorToast({
                title: t('uiKit.toastError.baseTitle'),
                message: t('uiKit.toastError.messageSelectLimit'),
            });
        }
        onChange(newValues);
    }, [onChange, getStateValueByKey, changeState, multiple, selectedOptions]);
    var resetSelectedOptionsHandler = useCallback(function () {
        multiple ? onChange([]) : onChange(undefined);
        if (getStateValueByKey('searchString')) {
            changeState('searchString', '');
        }
    }, [onChange, getStateValueByKey, changeState, multiple]);
    var toggleActive = useCallback(function () {
        changeState('isActive', !getStateValueByKey('isActive'));
    }, [changeState, getStateValueByKey]);
    var handleBlur = useCallback(function () {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
        changeState('isActive', false);
        if (getStateValueByKey('searchString')) {
            changeState('searchString', '');
        }
    }, [onBlur, changeState, getStateValueByKey]);
    return (_jsx(SearchInput, __assign({ withButton: true, labels: labels, isError: isError, prefix: prefix, postfix: postfix || _jsx(SelectIcon, { setSubscriber: setSubscriber }), disabled: disabled, additional: _jsx(OptionsListWrapper, __assign({ footer: !!optionsData.length && (_jsx(OptionsListFooter, { disabled: !value || !selectedOptions.length, onReset: resetSelectedOptionsHandler })), setSubscriber: setSubscriber }, { children: _jsx(OptionsList, { value: selectedOptions, options: optionsData, multiple: multiple, setSubscriber: setSubscriber, onSelect: handleSelect }) })), autoComplete: "off", onBlur: handleBlur, setSubscriber: setSubscriber, toggleActive: toggleActive, onChange: changeSearchStringHandler }, rest)));
};
