var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui-kit/modal';
import { Button } from 'ui-kit/button';
var BTN_STYLE = { marginLeft: '1rem' };
export var BaseModalFooter = function (_a) {
    var acceptButton = _a.acceptButton, cancelButton = _a.cancelButton, reverse = _a.reverse;
    var t = useTranslation().t;
    var buttons = [
        acceptButton ? __assign({ variant: 'primary', text: t('uiKit.modal.successButtonText') }, acceptButton) : null,
        cancelButton ? __assign({ variant: 'secondary', text: t('uiKit.modal.cancelButtonText') }, cancelButton) : null,
    ].filter(function (el) { return el; });
    if (reverse) {
        buttons.reverse();
    }
    return (_jsx(Modal.Footer, { children: buttons.map(function (_a, index) {
            var text = _a.text, rest = __rest(_a, ["text"]);
            return (_jsx(Button, __assign({ style: index ? BTN_STYLE : undefined, type: "button" }, rest, { children: text }), text));
        }) }));
};
