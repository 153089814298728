import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleType } from 'common/types/server-api-dtos';
import { EE, EventsBus } from '../../utils/events-bus';
import { useToastActions } from 'common/services/toast-service/hooks';
import { useProfileActions } from 'common/services/profile-service/hooks';
import { useCustomizationsActions } from 'common/services/customizations-service/hooks';
import { useCompanyAdditionalInfoActions } from 'common/services/company-additional-info-service/hooks';
import { useSupportRequestTypesActions } from 'common/services/support-request-types-service/hooks';
import { userService } from 'common/utils/user-service';
import { checkIsOnline } from 'common/helpers/check-is-online';
import { ONE_MINUTE } from 'common/constants/common-time';
var INTERVAL = ONE_MINUTE * 10;
export var EventsListener = function () {
    var fetchProfileAction = useProfileActions().fetchProfileAction;
    var fetchCustomizationsAction = useCustomizationsActions().fetchCustomizationsAction;
    var fetchAdditionalInfoAction = useCompanyAdditionalInfoActions().fetchAdditionalInfoAction;
    var fetchSupportRequestTypesAction = useSupportRequestTypesActions().fetchSupportRequestTypesAction;
    var _a = useToastActions(), addErrorToast = _a.addErrorToast, addToast = _a.addToast;
    var t = useTranslation().t;
    var showToast = useCallback(function (_a) {
        var title = _a.title, message = _a.message;
        addErrorToast({ title: title || t('eventsListener.errorToast.titleDef'), message: message });
    }, [addErrorToast, t]);
    useEffect(function () {
        var intervalId = Number(setInterval(function () {
            if (!userService.isLoggedIn() || !checkIsOnline()) {
                return;
            }
            fetchProfileAction();
            fetchSupportRequestTypesAction();
            if (userService.hasRole([RoleType.Manager, RoleType.Admin, RoleType.RootAdmin])) {
                fetchAdditionalInfoAction();
            }
        }, INTERVAL));
        EE.on(EventsBus.editProfile, fetchProfileAction);
        EE.on(EventsBus.updateCustomizations, fetchCustomizationsAction);
        return function () {
            clearInterval(intervalId);
            EE.off(EventsBus.editProfile, fetchProfileAction);
            EE.off(EventsBus.updateCustomizations, fetchCustomizationsAction);
        };
    }, [fetchCustomizationsAction, fetchProfileAction, fetchAdditionalInfoAction, fetchSupportRequestTypesAction]);
    var logout = useCallback(function () {
        addToast({
            title: t('eventsListener.errorToast.unauthorizeTitle'),
            message: t('eventsListener.errorToast.unauthorizeMessage'),
        });
    }, [addToast, t]);
    useEffect(function () {
        EE.on(EventsBus.showMessage, showToast);
        EE.on(EventsBus.logout, logout);
        return function () {
            EE.off(EventsBus.showMessage, showToast);
            EE.off(EventsBus.logout, logout);
        };
    }, [showToast, logout]);
    return null;
};
