import { useLayoutEffect } from 'react';
var YM_ID = 90230743;
var YM_IMG_SRC = "https://mc.yandex.ru/watch/".concat(YM_ID);
export var InitializationAdditionalScripts = function () {
    useLayoutEffect(function () {
        var _a, _b;
        if (process.env.NODE_ENV === 'production') {
            (_b = (_a = window).ym) === null || _b === void 0 ? void 0 : _b.call(_a, YM_ID, 'init', {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            });
            var noscript = document.createElement('noscript');
            var img = document.createElement('img');
            img.src = YM_IMG_SRC;
            img.style.position = 'absolute';
            img.style.left = '-9999px';
            noscript.appendChild(img);
            document.body.appendChild(noscript);
        }
    }, []);
    return null;
};
